
import { mapGetters } from 'vuex';

export default {
  name: 'PageComponent',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters({
      legal: 'settings/legal'
    }),
    isBlogPage() {
      return this.data?._type === 'blog';
    },
    isLegalPage() {
      return Object.keys(this.legal)
        .map((key) => this.legal[key].title)
        .includes(this.data.title);
    },
    showTitle() {
      return this.data?.showTitle;
    }
  },
  mounted() {
    // Useful resource to understand this line:
    // https://dmitripavlutin.com/vue-next-tick/
    this.$nextTick(() => (this.show = true));
  }
};
