import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=700ccd84&scoped=true"
import script from "./Page.vue?vue&type=script&lang=js"
export * from "./Page.vue?vue&type=script&lang=js"
import style0 from "./Page.vue?vue&type=style&index=0&id=700ccd84&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700ccd84",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeader: require('/vercel/path0/components/page/Header.vue').default,SocialMediaBar: require('/vercel/path0/components/elements/SocialMediaBar.vue').default,DetailPageHero: require('/vercel/path0/components/elements/DetailPageHero.vue').default,MarkAsClapped: require('/vercel/path0/components/elements/MarkAsClapped.vue').default,MarkAsFavourite: require('/vercel/path0/components/elements/MarkAsFavourite.vue').default,AnimationCustomFadeIn3: require('/vercel/path0/components/elements/Animations/CustomFadeIn3.vue').default,RelatedPost: require('/vercel/path0/components/elements/RelatedPost.vue').default,PageFooter: require('/vercel/path0/components/page/Footer.vue').default})
