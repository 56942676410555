
import { mapGetters } from 'vuex';
import getCustomRelatedPosts from '../../queries/getCustomRelatedPosts';
import getRelatedPostsQuery from '../../queries/getRelatedPostsQuery';

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'automatic'
    },
    relatedPosts: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      posts: []
    };
  },
  computed: {
    ...mapGetters({
      locale: 'navigation/locale',
      categories: 'page/getCategories'
    })
  },
  async created() {
    if (!this.relatedPosts || this.relatedPosts?.type === 'automatic') {
      const query = getRelatedPostsQuery(this.id, this.categories, this.locale);
      this.posts = await this.$sanity.fetch(query);
    } else {
      // load posts by _ref
      const postToFetch = [
        this.relatedPosts.automaticPosts?.post1._ref,
        this.relatedPosts.automaticPosts?.post2._ref,
        this.relatedPosts.automaticPosts?.post3._ref
      ];

      const query = getCustomRelatedPosts(this.id, postToFetch, this.locale);
      this.posts = await this.$sanity.fetch(query);
    }
  }
};
