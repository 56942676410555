import { render, staticRenderFns } from "./DetailPageHero.vue?vue&type=template&id=f9557108&scoped=true"
import script from "./DetailPageHero.vue?vue&type=script&lang=js"
export * from "./DetailPageHero.vue?vue&type=script&lang=js"
import style0 from "./DetailPageHero.vue?vue&type=style&index=0&id=f9557108&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9557108",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Back: require('/vercel/path0/components/elements/Back.vue').default,AnimationCustomFadeIn2: require('/vercel/path0/components/elements/Animations/CustomFadeIn2.vue').default,ArticleInfos: require('/vercel/path0/components/elements/ArticleInfos.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
