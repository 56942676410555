import { groq } from '@nuxtjs/sanity';
/**
 * In this query, we retrieve the post related to a post by category
 * Example query:
 *
 *
 *[_type=="blog" && __i18n_lang=="de_CH" &&
 (postCategories[]->entry.value match "mobilitaet" ||
 postCategories[]->entry.value match "social-media")]| order(_createdAt desc){
      _id, author->{name}, publicationDate, title, url, teaserImage }[0..2]
 */

export default function getRelatedPostsQuery(_id, categories = [], locale) {
  const relativePostQuery = `
    _type=="blog" &&
    __i18n_lang=="${locale}_CH" &&
    _id != "${_id}" &&
    dateTime(now()) >= dateTime(publicationDate)`;
  const categoriesMatches = categories.map((category) => {
    return `postCategories[]->entry.value match "${category.entry.value}"`;
  });
  const categoriesQuery = categories.length > 0 ? `&& (${categoriesMatches.join(' || ')})` : '';
  return groq`*[${relativePostQuery} ${categoriesQuery}]| order(publicationDate desc){
    _id, author->{name}, publicationDate, title, url, teaserImage}[0..2]`;
}
