import { groq } from '@nuxtjs/sanity';
/**
 * In this query, we retrieve the post related to a post by category
 * Example query:
 *
 *
 **[_type=="blog" &&
  live==true &&
  (dateTime(now()) >= dateTime(publicationDate)) &&
  __i18n_lang=="de_CH" &&
  !(_id in path("drafts.**"))][0..11] {
    _id, author->{name}, publicationDate, title, url, teaserImage
  }
 */

export default function getCustomRelatedPosts(currentPost, ids, locale) {
  const type = '_type == "blog"';
  const live = 'live == true';
  const beforePublicationDate = '(dateTime(now()) >= dateTime(publicationDate))';
  const lang = `__i18n_lang=="${locale}_CH"`;
  const dontLoadCurrentPost = `_id != "${currentPost}"`;
  const notInDraft = '!(_id in path("drafts.**"))';
  const objectProjection = `_id, author->{name}, publicationDate, title, url, readingTime,
  teaserImage, postCategories[]->{"label": entry.key}`;

  return groq`*[
    ${type} &&
    ${live} &&
    ${lang} &&
    ${beforePublicationDate} &&
    ${dontLoadCurrentPost} &&
    (${ids.map((id) => `_id == "${id}"`).join(' || ')}) &&
    ${notInDraft}
  ] {${objectProjection}}`;
}
