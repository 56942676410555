import { render, staticRenderFns } from "./RelatedPost.vue?vue&type=template&id=1fc4cf4a"
import script from "./RelatedPost.vue?vue&type=script&lang=js"
export * from "./RelatedPost.vue?vue&type=script&lang=js"
import style0 from "./RelatedPost.vue?vue&type=style&index=0&id=1fc4cf4a&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarkAsFavourite: require('/vercel/path0/components/elements/MarkAsFavourite.vue').default,Media: require('/vercel/path0/components/elements/Media/index.vue').default,ArticleInfos: require('/vercel/path0/components/elements/ArticleInfos.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
