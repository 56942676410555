
export default {
  data() {
    return {
      showCopiedMessage: false
    };
  },
  computed: {
    facebookShareLink() {
      // How to obtain an appID
      // https://webkul.com/blog/how-to-generate-facebook-app-id/
      // https://www.facebook.com/sharer/sharer.php?u=blog.shahednasser.com&quote=Awesome%20Blog!
      const baseUrl = 'https://www.facebook.com/sharer/sharer.php?';
      return `${baseUrl}u=${window.location.href}`;
    },
    // https://twitter.com/intent/tweet?text=Welcher%20Fahrradanh%C3%A4nger%20f%C3%BCrs%20Velofahren%20und%20Joggen?%20
    // &url=https://refresh-prod.visana.ch/de/blog/2022/babyjogger
    twitterShareLink() {
      const baseUrl = 'https://twitter.com/intent/tweet?';
      const text = 'the post title';
      const url = window.location.href;
      return `${baseUrl}text=${text}&url=${url}`;
    },
    linkedinShareLink() {
      // All these examples are not working
      // https://www.linkedin.com/shareArticle?mini=true&url=https%3A//www.sharelinkgenerator.com/&title=My%20test&summary=&source=
      // https://www.linkedin.com/shareArticle/?mini=true&url=http://localhost:3333/vom-kreislauf-eines-schuelerpults/&title=My%20post%20title
      // https://www.linkedin.com/shareArticle?&amp;url=https://refresh-prod.visana.ch/de/blog/2022/babyjogge
      // https://www.linkedin.com/sharing/share-offsite/?url=blog.shahednasser.com
      // https://www.linkedin.com/sharing/share-offsite/?url=https://umwelt-schweiz.ch/de/innovationen/vom-kreislauf-eines-schuelerpults/
      // const baseUrl = 'https://www.linkedin.com/shareArticle?mini=true&';
      const baseUrl = 'https://www.linkedin.com/shareArticle?mini=true&url=';
      const url = encodeURI(window.location.href);
      // const url = 'https://umwelt-schweiz.ch/de/innovationen/vom-kreislauf-eines-schuelerpults/';
      return `${baseUrl}${url}`;
    }
  },
  methods: {
    openFacebookPopup() {
      window.open(this.facebookShareLink, '_blank');
    },
    openTwitterPopup() {
      window.open(this.twitterShareLink, '_blank');
    },
    openLinkedinPopup() {
      window.open(this.linkedinShareLink, '_blank');
    },
    copyArticleLink() {
      navigator.clipboard.writeText(window.location);
      this.showCopiedMessage = true;
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, 1250);
    }
  }
};
