
import { mapGetters } from 'vuex';
export default {
  props: {
    _id: {
      type: String,
      default: ''
    },
    clap: {
      type: Number,
      default: 0
    },
    readingTime: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: 'title'
    },
    isBlog: {
      type: Boolean,
      default: false
    },
    author: {
      type: Object,
      default: () => {}
    },
    publicationDate: {
      type: String,
      default: 'readingTime'
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      locale: 'navigation/locale'
    })
  }
};
